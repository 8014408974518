<template>
    <div>
        <nav>
            <div>
                <router-link to="/"><i class="fal fa-arrow-left"></i> Назад</router-link>
            </div>
            <h4>Наши контакты</h4>
            <div></div>
        </nav>
        <p class="message">
            <span>Мы открыты к новым идеям и предложениям по улучшению сервиса. Также просим вас сообщать о любых проблемах,
                возникших в процессе работы!</span>
            <i class="far fa-info-circle"></i>
        </p>
        <div class="contactsContainer">
            <div class="contact">
                <p><i class="fal fa-at"></i> E-mail:</p>
                <a target="_blank" href="mailto:info@splitmyfunds.com">info@splitmyfunds.com</a>
            </div>
            <!-- <div class="contact"> -->
                <!-- <p><i class="fab fa-linkedin"></i> LinkedIn:</p> -->
                <!-- <a target="_blank" href="https://www.linkedin.com/in/solianyk/">linkedin.com/in/solianyk</a> -->
            <!-- </div> -->
            <div class="contact">
                <p><i class="fab fa-facebook-square"></i> Группа FB:</p>
                <a target="_blank" href="https://facebook.com/splitmyfunds">fb.com/SplitMyFunds</a>
            </div>
            <div class="contact">
                <p><i class="fab fa-vk"></i> Группа Vk:</p>
                <a target="_blank" href="https://vk.com/splitmyfunds">vk.com/SplitMyFunds</a>
            </div>
            <div class="contact">
                <p><i class="fab fa-telegram"></i> Канал Telegram:</p>
                <a target="_blank" href="https://t.me/splitmyfunds">t.me/SplitMyFunds</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'contacts',
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/forms.scss';

    nav {
        border-bottom: $border;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    a {
        display: inline-flex;
        align-items: center;
        color: $text;
        text-transform: uppercase;
        font-size: 20px;
        padding: 13px 0px;
        &:hover {
            text-decoration: none;
        }
        i {
            margin-right: 15px;
        }
    }

    h4 {
        display: inline-block;
        font-size: 20px;
        color: $text;
        text-transform: uppercase;
        padding: 11px 0px;
    }

    .contactsContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .contact {
            flex: 1 0 50%;
            max-width: 50%;
            margin: 20px 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            i {
                margin-right: 5px;
            }
            p {
                flex: 4;
                font-size: 20px;
                margin: 0;
                padding-right: 20px;
            }
            a {
                flex: 6;
                padding: 0;
            }
            &:first-child i {
                font-size: 18px;
            }
        }
    }

    @media screen and (max-width: 1024px) {
        a {
            font-size: 16px;
            padding: 11px 0px;
            i {
                margin-right: 12px;
            }
        }
        h4 {
            font-size: 18px;
            padding: 10px 0;
        }
        .contactsContainer .contact {
            flex: 1 0 100%;
            max-width: 100%;
        }
    }

    @media screen and (max-width: 610px) {
        nav {
            padding: 0 15px;
        }
        .contactsContainer {
            padding: 0 15px;
        }
    }

    @media screen and (max-width: 415px) {
        .contactsContainer .contact {
            p, a {
                flex: 1 0 100%;
                max-width: 100%;
            }
            p {
                margin-bottom: 10px;
            }
        }
    }
</style>
